import { useState } from "react";
import './style.css';
import { Link } from "react-router-dom";




// determine the style of the dropdown menu, the display is none.
// and when it get clicked it will change
let style = {
    display: "none",
};




function NavBar() {
    // use state the track the button is clicked or not
    const [isClicked, setIsClicked] = useState(false);

    /* menu_clicked is to open or close the drop down menu 
    if the use state true it will open, it depend on changing the style*/
    let menu_clicked = () => {
        if (isClicked) {
            style = {
                display: "none",
            };
        } else {
            style = {
                display: "flex",
                gap: "10px",
                position: "absolute",
            };
        }

        // after each click use state should change
        setIsClicked(!isClicked);
    };





    
    return (
        <>
            <div className="navbar">
                <div className="nav">
                    <div className="logo">
                        <Link to={`/`}><p>Ali Ahmed</p></Link>
                    </div>

                    <div className="list_nav">
                        <ul>
                            <li><Link to={`/app`}  className="ankerNav" href="/app">Blog posts</Link></li>
                            <li><Link to={`/app/about`}  className="ankerNav" href="/app/about">About</Link></li>
                        </ul>
                    </div>
                
                    <div className="wrapper">

                       {/* this is the vertical line */}
                        <hr className="hr"/>

                        <div className='icon' onClick={menu_clicked}>
                            <span className="dropdown-icon"></span>
                            <span className="dropdown-icon"></span>
                            <span className="dropdown-icon"></span>
                        </div>
                    </div>

                </div>
            </div>


            {/* dropdown menu */}
            <div className="dropmenu" style={style}>
                <div className="select">
                    <Link className="anker" to={"/app"}>Blog posts</Link>
                    <Link className="anker" to={"/app/about"}>About</Link>
                </div>
            </div>

            <hr id="hrNav"/>
        </>
    )
}

export default NavBar;