

function NotFound() {

    return(
        <>
            404 Not Found
        </>
    );
}


export default NotFound;