import axios from 'axios';
import './style.css';
import { useEffect, useState } from 'react';
import AdminPrev from '../form/AdminPrev';
import { Link } from 'react-router-dom';





function Posts() {
    const [data, setData] = useState([]);



    let FetchData = async () => {
        await axios.get(process.env.REACT_APP_ALL_POSTS)
            .then(res => setData(res.data))
            .catch(err => console.log(err));
    }


    useEffect(() => {
        FetchData()
    }, []);


    
    return (
        <>

            {
                data.map((v, i) => {
                    return (
                        <>
                            <div className="wrapPost">
                                <div className="post-container">
                                    <div className="titleDate">
                                        <Link to={`/app/post/${data[i]._id}`}>
                                            <h2>{data[i].title}</h2>

                                            <AdminPrev title={data[i].title} />
                                        </Link>

                                        <p>{data[i].date}</p>                                        
                                    </div>

                                    <div className="seeMore">
                                        <p>{data[i].description}</p>
                                    </div>
                                </div>
                            </div>

                            <hr className='hrPost' />
                        </>
                    )
                })
            }
        </>
    );
}

export default Posts;