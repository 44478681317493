import './style.css';



function About() {

    return(
        <div className="about-wrap">
            <div className="about-content">
                Hi, I'm Ali Ahmed, a Computer Engineer studying at the University of Baghdad. As a passionate Full Stack Developer, I specialize in using the MERN stack and GoLang to build innovative web applications.
                <br/>
                I am currently pursuing my degree in Computer Engineering at the University of Baghdad, where I've gained a strong foundation in computer science and engineering principles.
                <br/>
                <br/>
                My technical skills include working with the MERN stack (MongoDB, Express.js, React, Node.js) and GoLang to develop robust and scalable web applications. I also have hands-on experience with MongoDB databases, ensuring efficient data storage and retrieval.
                <br/>
                <br/>
                I have had the privilege of working on diverse web development projects, where I've honed my skills and delivered solutions that meet client requirements.
                <br/>
                <br/>
                I am truly passionate about computer engineering and the endless possibilities it offers in solving real-world problems through innovative software solutions. Every day, I am motivated to learn and grow in this dynamic field.
                <br/>
                <br/>
                When I'm not coding. I believe that a diverse set of interests fuels creativity and helps me think outside the box.
                <br/>
                Feel free to explore my portfolio to see examples of my work. If you have a project in mind or would like to collaborate, don't hesitate to get in touch. I'm always excited to take on new challenges and work with like-minded individuals.
            </div>
        </div>
    )
}

export default About;