import { isAuth, handlePostOp } from "../../helpers/helpers";



// this component is for showing buttons for update and delete for auth 
function AdminPrev({ title }) {


    const handleDelete = (event, title) => {
        event.preventDefault();
        handlePostOp(process.env.REACT_APP_DELETE_POST, title)
    }



    return(
        <>
            {isAuth() && (
                <div>
                    <button onClick={event => handleDelete(event, title)}>update</button>
                    <button onClick={event => handleDelete(event, title)}>delete</button>
                </div>
            )}
        </>
    )
}




export default AdminPrev;