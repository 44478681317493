import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import makeRequest from "../../helpers/helpers";




function LoginForm() {
    const navigate = useNavigate();

    const [auth, setAuth] = useState(localStorage.getItem('auth-token'));
    
    const[user, setUser] = useState({
        email: '',
        password: ''    
    });


    // if user is auth it will redirect to the form page
    useEffect(()=> {
        if(auth) {
            navigate('/app/auth/form');
        }
    }, [auth, navigate]);



    const handleSubmit = (event) => {
        event.preventDefault();

        // here it will check if user is auth or not
        makeRequest(process.env.REACT_APP_LOGIN, user);
    }



    const handleEmail = (event)=> {
        const email = event.target.value;

        setUser( prev => ({
            ...prev,
            email : email,
        }));
    }



    const handlePass = (event)=> {
        const pass = event.target.value;

        setUser(prev => ({
            ...prev,
            password : pass
        }));

    }



    return(
        <>
            <form onSubmit={handleSubmit}>
                <label htmlFor="">email</label>
                <input type="email" name="" id="" value={user.email} onChange={handleEmail}/>

                <label htmlFor="">password</label>
                <input type="password" name="" id="" value={user.password} onChange={handlePass}/>

                <button type="submit">submit</button>
            </form>

            <Outlet />
        </>
    );
}




export default LoginForm;