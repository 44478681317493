import './style.css';

function Footer() {

    return (
        <div className="" style={{ display: 'flex', flexDirection: 'column', minHeight: "100vh" }}>

            <footer>
                &copy; Ali Ahmed
            </footer>
        </div>

    )
}

export default Footer;