import 'bootstrap/dist/css/bootstrap.css';
import NavBar from './components/nav/NavBar';
import Posts from './components/posts/Posts';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import OnlyPost from './components/posts/OnlyPost';
import About from './components/About/About';
import Footer from './components/Footer/Footer';
import Auth from './components/Auth/Auth';
import NotFound from './components/NotFoundPage/NotFound';
import BlogForm from './components/form/BlogForm';
import LoginForm from './components/login/LoginForm';






function App() {
  return (
    <>
      <NavBar />

      <Routes>
        <Route path='/' index element={<Home />} />
        <Route path='/app' element={<Posts />} />
        <Route path='/app/post/:id' element={<OnlyPost />} />
        <Route path='/app/about' element={<About />} />
        <Route path='/app/auth' element={<Auth />} >
          <Route path="/app/auth/login" element={<LoginForm />} />
          <Route path="/app/auth/form" element={<BlogForm />} />
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>

      <Footer />
    </>
  );
}

export default App;