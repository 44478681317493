import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DisplayImage from "./ImgProcess";




function OnlyPost() {
    const [post, setPost] = useState('');


    // to get the url and extract the title from it
    const location = useLocation();
    const id = location.pathname.split('/')[3];

    // get specific blog post
    let FetchPost = async () => {
        axios.get(process.env.REACT_APP_POST, {
            // get blog post based on the id
            params: {
                id
            }
        })
            .then(res => { console.log(res); setPost(res.data) })
            .catch(err => console.log(err));
    }


    // fetch the when component get mount 
    useEffect(() => {
        FetchPost();
    }, []);




    return (
        <>
            <article id="postSection">
                <div className="wrap-post">

                    <div className="title">
                        {post.title}
                    </div>

                    <div className="content" dangerouslySetInnerHTML={{ __html: post.info }}></div>
                </div>
            </article>
        </>
    );
}



export default OnlyPost;