import axios from 'axios';
import { useState } from 'react';



function BlogForm() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [info, setInfo] = useState('');
    const [css, setCss] = useState('');
    const [images, setImages] = useState('');

    const headers = {
        'auth-token': localStorage.getItem('auth-token'),
        'Content-Type': 'multipart/form-data',
    };


    // to set image
    const handleImages = (event) => {
        setImages(event.target.files[0]);
    }


    // to make an post request 
    const postRequest = (formData) => {
        axios.post(process.env.REACT_APP_BLOG, formData, { headers: headers })
            .then(res => console.log(res))
            .catch(err => console.log(err));
    };




    const formData = () => {
        let formData = new FormData();

        formData.append("title", title);
        formData.append("description", description);
        formData.append("info", info);
        formData.append("images", images);
        formData.append("css", css);
        return formData
    };



    const handleSubmit = (event) => {
        event.preventDefault();
        const data = formData();
        postRequest(data);
    };




    return (
        <div className="">
            <form action="" onSubmit={handleSubmit} >

                <div>
                    <label htmlFor="">title</label>
                    <input type="text" name="title" id="" value={title} onChange={e => setTitle(e.target.value)} />
                </div>

                <div>
                    <label htmlFor="">description</label>
                    <input type="text" name="description" value={description} onChange={e => setDescription(e.target.value)} />
                </div>

                <div>
                    <label htmlFor="">info</label>
                    <textarea name="info" id="" cols="100" rows="10" value={info} onChange={e => setInfo(e.target.value)}></textarea>
                </div>
                
                <div>
                    <label htmlFor="">css</label>
                    <textarea name="css" id="" cols="100" rows="10" value={css} onChange={e => setCss(e.target.value)}></textarea>
                </div>

                <input type="file" name="images" id="" multiple accept="image/*" onChange={handleImages} />
                <button type="submit">submit</button>
            </form>
        </div>
    );
}

export default BlogForm