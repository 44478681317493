import 'bootstrap/dist/css/bootstrap.css';
import './style.css';
import stackoverflow from './icons/icons8-stack-overflow-50.svg';
import linkedin from './icons/icons8-linkedin-30.svg';
import github from './icons/icons8-github-30.svg';




function Home() {
    return (
        <div className='home-wrapper'>
            Ali Ahmed.ninja

            <div className='home-icons'>
                <li><a href='https://github.com/AliLtRP'><img src={github} alt='' style={{ width: '30px', height: '30px' }} /></a></li>
                <li><a href='https://www.linkedin.com/in/aliltrp/'><img src={linkedin} alt='' style={{ width: '30px', height: '30px' }} /></a></li>
                <li><a href='https://stackoverflow.com/users/17051118/ali-shahen'><img src={stackoverflow} alt='' style={{ width: '27px', height: '27px' }} /></a></li>
            </div>
        </div>
    );
}



export default Home;