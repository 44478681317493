import axios from "axios"




export function isAuth() {
    if(localStorage.getItem('auth-token')) {
        return true;
    } 
    return false;
}



// this function will make a request to check if user is auth, if auth it will return token 
async function makeRequest(url, data) {

    const formData = new FormData();

    formData.append('email', data.email);
    formData.append('password', data.password);

    await axios.post("http://localhost:8000/app/user/login", formData, {
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(res => {
            localStorage.setItem('auth-token', res.data);
        })
        .catch(err => console.log(err));
}


// delete post 
export async function handlePostOp(url, title) {

    await axios.delete(url,  {
        headers: {
            'auth-token' : localStorage.getItem('auth-token')
        },
        params: {
            title: title
        }
    })
        .then(res => console.log(res))
        .catch(err => console.log(err));

}


// update post 

export default makeRequest;