import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";




function Auth() {

    const navigate = useNavigate();
    const [auth, setAuth] = useState(localStorage.getItem('auth-token')); // Replace with your authentication logic
  
    useEffect(() => {
      if (auth) {
        navigate('/app/auth/form');
      } else {
        navigate('/app/auth/login');
      }
    }, [auth, navigate]);


    return(
        <>
            
            <Outlet />
        </>
    )
}


export default Auth;